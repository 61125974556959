import React from 'react';
import './Header_video.css';
import videoBgd from './img/jumbotron.mp4';
import { getWindowDimension } from '../../tools/sizeScreen.js';
import ReactDOM from "react-dom";

class Header_video extends React.Component {
    constructor(props) {
        super(props);
        const sizeScreen = getWindowDimension();
        const widthVideo = sizeScreen.width;

        this.state= {
            sizeScreen: sizeScreen,
            widthVideo: widthVideo,
            heightVideo: widthVideo*108/192,
            overlayStyle: {width: widthVideo + "px", height: widthVideo*108/192 + "px"},
            classOverlay: "display-4",
            classTitle: "display-1",
        }

        this.sizeWindowChange = this.sizeWindowChange.bind(this);

       window.addEventListener('resize', function() {
            this.sizeWindowChange();
        }.bind(this));
    }
    componentDidMount() {
        console.log("reinitialisation !!!!!!!");
    }
    sizeWindowChange() {
            const sizeScreen = getWindowDimension()
            const widthVideo = sizeScreen.width;
                 this.setState({
                     sizeScreen: sizeScreen,
                     widthVideo: widthVideo,
                     overlayStyle: {height: widthVideo*108/192 + "px"},
                    })

    }
    render() {
         const title = this.props.title;
        const subtitle = this.props.subtitle;
        const widthVideo = this.state.widthVideo;
        const overlayStyle = this.state.overlayStyle;
             return (
                <header>
                    <video playsInline="playsinline" preload="metadata" autoPlay="autoplay" muted="muted" loop="loop" width={widthVideo}>
                        <source src={videoBgd} type="video/mp4"/>
                    </video>
                    <div className="overlay d-flex justify-content-around align-items-center" style = { overlayStyle }>
                        <div className="d-flex justify-content-around align-items-center p-0 m-0">
                            <div className="p-0 m-0 text-white text-center" >
                                 <p className="mt-3 p-0 display-1"><span className="text-warning">M</span>ic<span className="text-warning">M</span>ac <span className="text-warning">S</span>trategy.</p>
                                 <h1 className="display-4">{title}</h1>
                                 <h2 className="display-4">{subtitle}</h2>
                            </div>
                        </div>
                    </div>
                </header>
            );
        }
}

export default Header_video;