import React from "react";
import ReactDOM from "react-dom";
import './home.css';
import Header_video from "../../library/reactjs/header_video/Header_video.jsx";
import collaboration from "./img/collaboration.jpg";
import architect from "./img/architect.jpg";
import evolution from "./img/evolution.jpg";
import mmstype from "./img/mmsType.jpg";

// Pour utiliser la navbarMMS sur toutes les pages :
import navBarMMS from "../../library/reactjs/navBarMMS/NavBarMMS.js";
navBarMMS();

ReactDOM.render(
    <img className="img-fluid" src={ collaboration } alt="participer" width={"100%"} height={"100%"}/>,
    document.getElementById("collaboration")
);
ReactDOM.render(
    <img className="img-fluid" src={ architect } alt="créer" width={"100%"} height={"100%"}/>,
    document.getElementById("architect")
);
ReactDOM.render(
    <img className="img-fluid" src={ evolution } alt="réparer" width={"100%"} height={"100%"}/>,
    document.getElementById("evolution")
);
ReactDOM.render(
    <img  className="img-fluid" src={ mmstype }  alt="MicMac Battle" width={"100%"} height={"100%"}/>,
    document.getElementById("mmstype")
);

// *************************   EN-TETE  VIDEO   **************************

const headerVideo = <Header_video subtitle={"Création de sites internet dans l'Aisne."} title={"Michaël Le Thiec"} />
 ReactDOM.render(
        headerVideo,
        document.getElementById('headerVideo')
    );


// ***********************************************************************
